import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuthNewService } from '../../../service/auth-new.service';
import { delay } from 'rxjs/operators';
import { access } from 'fs';
import { StorageService } from '../../../service/storage.service';
import { HttpParams } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-user-sign-in',
  templateUrl: './user-sign-in.component.html',
  styleUrls: ['./user-sign-in.component.scss']
})
export class UserSignInComponent implements OnInit, OnDestroy {

  signinForm!: FormGroup;
  codeForm!: FormGroup;
  submitted = false;
  username!: string;
  password!: string;
  code!: string;
  user!: CognitoUser;
  userAuthenticated = false;
  mfaFlag = false;
  userGroup = new BehaviorSubject<string>('');
  hide = true;
  error: string;
  userRole: string;
  MfaUser
  private currentLoginUser: Subscription;
  private signInError: Subscription;
  resendEnabled: boolean = false;
  resendTimeout: any;
  codeReceived;
  TokenAccess;
  customAttributes;
  accountBlocked: boolean = false;
  extractDays: number;
  navigationExtras: NavigationExtras;
  forgotbuttondisabled: boolean = false;
  countSignInFailedAttempt: number = 0;
  functionCall: boolean = false;

  constructor(public formBuilder: FormBuilder, public router: Router, private authservice: AuthNewService,
    private storageService: StorageService) {
    this.resendTimeout = setTimeout(() => {
      this.resendEnabled = true;
    }, 20000);
  }

  ngOnInit(): void {
    this.resendEnabled = false;
    this.error = ''
    this.signinForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.codeForm = this.formBuilder.group({
      code: ['', Validators.required]
    });
  }
  get formControls() { return this.signinForm.controls; }
  get formControls1() { return this.codeForm.controls; }

  signInNew() {
    this.functionCall = true;
    this.submitted = true;
    this.userAuthenticated = false;
    if (this.signinForm.invalid) {
      return;
    }

    this.username = this.signinForm.value.username;
    this.password = this.signinForm.value.password;

    this.customAttributes = {
      apiType: 'login',
      hashpassword: CryptoJS.SHA256(this.password).toString(),
    };


    this.authservice.signIn(this.username, this.password, this.customAttributes);

    this.signInError = this.authservice.signinError.subscribe((error: any) => {
      if (error) {
        console.log(error)
        this.submitted = false;
        if (error instanceof Error && error.message.includes('User does not exist.')) {
          this.error = 'User does not exist.'
        }
        else if (error instanceof Error && error.message.includes('Unable to login because of security reasons.')) {
          this.error = 'Login blocked for security reasons. Please contact support.'
        }

        else if (error instanceof Error && (error.message.includes('You have crossed the signin attempts') || error.message.includes("User is disabled."))) {
          this.forgotbuttondisabled = true;
          this.error = 'Your account has been blocked. Please contact support.';
        }
        else {
          this.error = '';
          if (this.functionCall) {
            this.countSignInFailedAttempt++;
            this.functionCall = false
          }

          if (this.countSignInFailedAttempt <= 3) {
            this.error = `Username or Password is incorrect.Attempts left ${4 - this.countSignInFailedAttempt}`;
          }

        }

      } else {
        this.error = '';

      }
    })

    this.authservice.user.subscribe((user: any) => {
      if (user) {
        const accessToken = user.signInUserSession?.accessToken['jwtToken'];
        if (accessToken) {
          const tokenParts = accessToken?.split('.');
          const payload = JSON.parse(atob(tokenParts[1]));
          this.extractDays = payload?.days_left;
          this.navigationExtras = {
            queryParams: { days_left: this.extractDays }
          };
        }


        if (user.challengeName === 'SMS_MFA') {
          this.error='';
          this.codeReceived = user.challengeParam['CODE_DELIVERY_DESTINATION']

          this.mfaFlag = true;
        } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.router.navigate(['/extra-layout/new-password']);
        }
        else {

          const storedData = this.storageService.get('access');
          const userId = this.storageService.get('username')
          let params = new HttpParams();
          params = params.append('userId', userId);
          if (this.extractDays && (this.extractDays > 0) && (this.extractDays <= 10)) {
            this.router.navigate(['/extra-layout/password-validity'], this.navigationExtras);
          }
          else if (this.extractDays <= 0) {
            this.router.navigate(['/extra-layout/new-password'], { queryParams: { passtype: 'Change' } });
          }
          else if (storedData == 'QA') {
            this.router.navigate(['default-layout/user-details'])
          }
          else
            this.router.navigate(['default-layout/previous-analysis'])

        }
      }
    })
  }

  confirmationCode() {
    this.code = this.codeForm.value.code;
    // this.authservice.confirmCode(this.code);
    this.authservice.confirmCode(this.code, (error, newUser) => {
      if (error) {
        // Handle error when confirmation code is incorrect
        console.error(error);
        this.error = 'Incorrect Confirmation Code';
      } else {

        if (newUser?.signInUserSession) {
          this.userAuthenticated = true;
          this.user = newUser;

          this.authservice.getMFACurrentUser();
          this.currentLoginUser = this.authservice.MfaUser.subscribe((user) => {
            this.userRole = this.storageService.get('access');

            if (this.userRole == 'QA') {
              this.router.navigate(['default-layout/user-details']);
            } else {
              this.router.navigate(['default-layout/previous-analysis']);
            }
          });
        }
      }
    })

  }

  clearError() {
    this.error = '';
  }
  resendOTP(): void {
    this.error = null
    this.signInNew();
    this.resendEnabled = false;
    clearTimeout(this.resendTimeout);
    this.resendTimeout = setTimeout(() => {
      this.resendEnabled = true;
    }, 20000);

  }



  ngOnDestroy() {
    // Unsubscribe from the observable to avoid memory leaks
    clearTimeout(this.resendTimeout);
    if (this.currentLoginUser) {
      this.currentLoginUser.unsubscribe();
    }
    if (this.signInError) {
      this.signInError.unsubscribe();
    }
  }
}
