import { Injectable } from '@angular/core';
import { User } from '../model/types';
import { S3Factory } from '../../utils';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import 'rxjs/add/operator/map';
import {Subject} from 'rxjs';
import {SearchResult} from '../model/analysis-details.model';
import {environment} from '../../environments/environment';
import { AuthNewService } from './auth-new.service';
import { StorageService } from './storage.service';
import { DatePipe } from '@angular/common';
declare var TextDecoder;

@Injectable({
  providedIn: 'root'
})
export class DownLoadService {

  private signedInUser: User;
  private region: string;
  private signedUrlExpireSeconds = 60 * 5;
  dataLoaded = new Subject<SearchResult[]>();
  BUCKET: string =environment.bucket;
  graph_variable: string;
  newFileName:string;
  contentDisposition:string;


  constructor(private http: HttpClient,
              private authService: AuthNewService,
              private storageService: StorageService,
              private datePipe: DatePipe) {
    this.region = environment.defaultRegion || 'us-east-2';
 
  
  }

  setSignedInUser(user: User) {
    this.signedInUser = user;
  }

  // Upload status updates

  setRegion(region: string) {
    this.region = region;
  }

  listFiles() {
    return S3Factory.getS3(this.region).listObjectsV2({
      Bucket: this.BUCKET,
      Prefix: [this.signedInUser.username, this.signedInUser.userId].join('/')
    }).promise();
  }

  getUrl(key: string,replace_filename= true,analysisType?:string,fileExcelName?:string) {
    
      if(analysisType =='AP'){
        if (replace_filename) {
          this.contentDisposition = 'attachment; filename="RedThread_Analysis_Report.xlsx"'
        } else {
          this.contentDisposition = 'attachment'
        }
        return S3Factory.getS3(this.region).getSignedUrl('getObject', {
          Bucket: this.BUCKET,
          Key: key,
          ResponseContentDisposition: this.contentDisposition,
          Expires: this.signedUrlExpireSeconds
        });
      }
     else{
      this.newFileName = `RedThread_Analysis_Report_${fileExcelName}.xlsx`;
      return S3Factory.getS3(this.region).getSignedUrl('getObject', {
        Bucket: this.BUCKET,
        Key: key,
        ResponseContentDisposition: 'attachment; filename ="' + this.newFileName + '"',
        Expires: this.signedUrlExpireSeconds
      });
     }
   
  
  }

  async getS3Obj(key: string) {
    try {
      const params = {
        Bucket: this.BUCKET,
        Key: key
      }
      const data = await S3Factory.getS3(this.region).getObject(params).promise();
      const result =  new TextDecoder('utf-8').decode(data.Body);
      return  result
    } catch (e) {
      throw new Error(`Could not retrieve file from S3: ${e.message}`)
    }
 /*   S3Factory.getS3(this.region).getObject({
      Bucket: this.BUCKET,
      Key: key
    }, function (err, data) {
      if (err) { console.log(err, err.stack); } else {
        const string = new TextDecoder('utf-8').decode(data.Body);

   
      }
    })
*/  }






}
