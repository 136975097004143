import { ChangeDetectorRef, Component, OnInit, OnDestroy, ChangeDetectionStrategy, ViewChild, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { DownloadFile, SearchResult } from '../../../model/analysis-details.model';
import { DBService, DownLoadService } from '../../../service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../../../service/auth.service';
import { interval } from 'rxjs/internal/observable/interval';
import { User } from '../../../model/types';
import { SharedService } from '../../../layouts/shared-service';
import { Subscription } from 'rxjs/Subscription';
import { AuthNewService } from '../../../service/auth-new.service';
import { forkJoin, from, Observable, of, Subject } from 'rxjs';
import { concatMap, delay, finalize, last, shareReplay, switchMap, take, takeUntil, catchError, debounceTime } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { StorageService } from '../../../service/storage.service';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import jsPDF from 'jspdf';
const htmlToPdfmake = require("html-to-pdfmake");
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
import { DatePipe } from '@angular/common';
import * as Plotly from 'plotly.js-dist';
import { std, mean } from 'mathjs';
import { DropDownService } from '../../../service/drop-down.service';
import { error } from 'console';
import { debounce } from 'rxjs-compat/operator/debounce';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { bool } from 'aws-sdk/clients/signer';



@Component({
    selector: 'app-qpcr-result-page',
    templateUrl: './qpcr-result-page.component.html',
    styleUrls: ['./qpcr-result-page.component.scss']
})
export class QpcrResultPageComponent implements OnInit {
    maxDate;
    pageTitle = 'Analyze Report/Data';
    searchResult: SearchResult = <SearchResult>{};
    searchResultData: boolean = true;
    isFetching: boolean = true;
    signedInUser: any;
    currentUser: User;
    illegalUser: boolean;
    usr: any;
    analysisId = '';
    navigationSubscription: Subscription;
    qpcrTables = []
    step = 0;
    urlPath: string;
    signedInUserAccess;
    errorResult: string;
    private singleSearchData: Subscription;
    private currentLoginUser: Subscription;
    private PostEventData: Subscription;
    private getEventData: Subscription;
    EventDataUrl: string = environment.serverUrl + '/event-data';
    AnalysisDataURL: string = environment.serverUrl + '/analysis-data';
    regressionUrl: string = environment.serverUrl + '/regression'

    DownloadFileobject: DownloadFile = <DownloadFile>{};
    AnalysisViewType: string;
    QpcrPdfData
    AuditPdfData
    @ViewChild('htmlData', { static: false }) htmlData!: ElementRef;
    @ViewChild('auditData', { static: false }) auditData!: ElementRef;
    logo: string;
    graphdata;
    graphHistoryData;
    checkboxSelected: boolean = false;
    commentText: { run_id: string, selected_samples: string[], sample_flag_selected: boolean[], comments: string[], CQ: string[], WellPosition: string[] }[] = [];
    selectedSampleNames: { run_id: string, selected_samples: string[] }[] = [];
    selectedComments: string[] = [];
    targetInfoList: { name: string, type: string }[] = [];
    analysisStatusText: string = 'Gathering Information ';
    graphHistoryDataKeys: string[];
    initialSampleNames = [];
    calc_CV = [];
    RE = [];
    submitResultLoader: boolean = false
    submitEnabled: boolean = true;
    disableSubmit: boolean = true
    executionId: string;
    selectedRows: number[] = [];
    regressionApiError: string = '';
    recalculationStates: boolean[] = [];
    panelStates: boolean[] = [];
    groupedData: any[] = [];
    fetchingEventData: boolean = true;
    pollingSubscription: Subscription;
    userGroup: any;
    userOrganization: any;
    private stopPolling = new Subject<void>();
    graphLoadingStates: boolean[] = [];
    @ViewChild('RApaginator', { static: false }) paginator: MatPaginator;
    paginatedData = new MatTableDataSource<any>([]);
    currentPageData = [];
    filteredData = '';
    pageIndex = 0;
    lastPageIndex: number = 0;
    showSubmitButton: boolean = false;
    hasVisitedLastPage: boolean = false;


    constructor(
        private http: HttpClient,
        private dbService: DBService,
        private activatedroute: ActivatedRoute,
        private AuthService: AuthNewService,
        private router: Router,
        private dropDownService: DropDownService,
        private _sharedService: SharedService,
        private downloadService: DownLoadService,
        private storageService: StorageService,
        public datepipe: DatePipe,
        private cdr: ChangeDetectorRef,
    ) {
        this._sharedService.emitChange(this.pageTitle);
    }
    
    getDisplayString(key) {
        return this.dropDownService.nameDescMap.get(key);
    }


    async ngOnInit() {
        this.pollForResult();
        this.AnalysisViewType = this.activatedroute.snapshot.paramMap.get('type');
        try {
            const data = await this.dbService.singleSearch.pipe(take(1)).toPromise();
            if (Object.keys(data).length === 0) {
                this.searchResultData = false;
            } else {
                this.isFetching = false;

            }
            data && this.populateResultData(data);
            this.loadImageAsBase64('/assets/img/App-Biodata.png');
        } catch (error) {
            console.error('Error occurred:', error);
        }
    }


    calculateLastPageIndex() {
        setTimeout(() => {
            if (this.paginator && this.paginator.length > 0) {
                this.lastPageIndex = Math.ceil(this.paginator.length / this.paginator.pageSize) - 1;
                if (this.paginator.length <= this.paginator.pageSize) {
                    this.hasVisitedLastPage = true;
                    this.showSubmitButton = true;
                }
            } else {
                console.log("Paginator not yet initialized. Retrying...");
                setTimeout(() => this.calculateLastPageIndex(), 100); // Retry after 100ms
            }
        }, 0);
    }

    checkIfLastPage() {
        if (this.paginator) {
            // Check if user is on last page
            if (this.paginator.pageIndex === this.lastPageIndex) {
                this.hasVisitedLastPage = true; // Mark as visited
            }
    
            // Show button if user has visited last page at least once
            this.showSubmitButton = this.hasVisitedLastPage;
        }
    }

    loadImageAsBase64(url: string) {
        this.http.get(url, { responseType: 'blob' }).subscribe((blob: Blob) => {
            const reader = new FileReader();
            reader.onload = () => {
                this.logo = reader.result as string;
            };
            reader.readAsDataURL(blob);
        });
    }

    populateResultData(data: SearchResult) {
        this.searchResult = data;
        this.errorResult = this.searchResult.error_message;
        this.QpcrPdfData = this.searchResult.pdf_data;
        let output_file = this.getSingedURL(this.searchResult.output_result_path);
        this.searchResult.output_file_path = output_file;
        this.graphdata = this.searchResult.GraphData;
        this.recalculationStates = new Array(this.graphdata?.length).fill(false);
        this.panelStates = new Array(this.graphdata?.length).fill(false);
        this.graphHistoryData = this.searchResult.initial_graph_data;
        this.paginatedData = new MatTableDataSource(this.graphdata);
        setTimeout(() => {
            this.paginatedData.paginator = this.paginator;
            if (this.paginator) {
                this.calculateLastPageIndex(); // Calculate last page index here
                this.paginator?.page.subscribe(() => {
                    this.pageIndex = this.paginator.pageIndex;
                    this.checkIfLastPage();
                    this.renderGraphsForCurrentPage(); // Re-render graphs for the visible page
                });
            }
            this.renderGraphsForCurrentPage();
        }, 0);

        let eventName = 'View Data Handling Summary Page';
        this.callInitialEventData(eventName);

    }

    callInitialEventData(eventName) {
        this.fetchingEventData = true;
        const downloadFileObject = {
            userId: this.storageService.get('username'),
            eventName: eventName,
            analysisId: this.analysisId,
            userName: this.storageService.get('name'),
            userRole: this.storageService.get('access')
        };
        let params = new HttpParams();
        params = params.append('analysisId', this.analysisId);
        return this.http.post(this.EventDataUrl, downloadFileObject)
            .subscribe(
                (response) => {
                    this.getEventData = this.http.get(this.EventDataUrl, { params: params }).subscribe(
                        (res) => {
                            this.AuditPdfData = res;
                            this.fetchingEventData = false;
                        }
                    )
                }
                ,
                (error) => {
                    console.error('API call error:', error);
                }
            );

    }

    callEventData(eventName, date = null): Observable<any> {
        const downloadFileObject = {
            userId: this.storageService.get('username'),
            eventName: eventName,
            analysisId: this.analysisId,
            userName: this.storageService.get('name'),
            userRole: this.storageService.get('access'),
            analysisDate: date
        };
        let params = new HttpParams();
        params = params.append('analysisId', this.analysisId);
        return this.http.post(this.EventDataUrl, downloadFileObject)
    }

    GetEventData() {
        this.fetchingEventData = true;
        let params = new HttpParams();
        params = params.append('analysisId', this.analysisId);
        this.getEventData = this.http.get(this.EventDataUrl, { params: params }).subscribe(
            (res) => {
                this.AuditPdfData = res;
                this.fetchingEventData = false;
            },
            (error) => {
                console.error('API call error:', error);
            }
        )

    }

    applyFilter(filterValue: string) {
        const filterText = filterValue.trim().toLowerCase();
        this.paginatedData.filter = filterText;
        const matchedItem = this.paginatedData.data.find(item =>
            JSON.stringify(item).toLowerCase().includes(filterText));

        const matchedIndex = this.paginatedData.data.findIndex(item =>
            JSON.stringify(item).toLowerCase().includes(filterText));

        if (matchedItem !== undefined && matchedIndex !== -1) {
            this.filteredData = matchedItem;
            // Pass single object and single index to the plot function
            this.CreateUpdatePlot(matchedItem, matchedIndex);
        } else {
            this.filteredData = null;
            console.log("No matching data found.");
        }
    }


    renderGraphsForCurrentPage(): void {
        if (!this.paginatedData || !this.paginator) return;
        // Get the visible rows based on the current page
        this.currentPageData = this.paginatedData.filteredData.slice(
            this.paginator.pageIndex * this.paginator.pageSize,
            (this.paginator.pageIndex + 1) * this.paginator.pageSize
        );
        this.currentPageData.forEach(dataObj => {
            let storedComment = this.commentText.find(c => c.run_id === dataObj.run_id);
            if (storedComment) {
                dataObj.comment = dataObj.comment.map((tableDataObj, index) => {
                    return {
                        ...tableDataObj,
                        comment: storedComment.comments[index] || tableDataObj.comment
                    };
                });
            }
        });

        setTimeout(() => {
            this.currentPageData.forEach((dataObj, index) => {
                this.CreateUpdatePlot(dataObj, index);
            });
        }, 200);

        this.cdr.detectChanges();
        this.initiateGraphData();
    }

    initiateGraphData() {
        if (this.currentPageData && this.currentPageData.length > 0) {
            this.paginatedData.data.forEach(dataObj => {
                this.initializeComments(dataObj)
            });
            if (!this.commentText) {
                this.commentText = []; // Ensure commentText is initialized
            }
            for (const [index, dataObj] of this.paginatedData.data.entries()) {
                new Promise(resolve => {
                    setTimeout(() => {
                        let existingComment = this.commentText.find(c => c.run_id === dataObj.run_id);
                        if (!existingComment) {
                            let newCommentEntry = {
                                run_id: dataObj.run_id,
                                selected_samples: [],
                                sample_flag_selected: [],
                                comments: [],
                                CQ: [],
                                WellPosition: []
                            };

                            dataObj.comment.forEach(tableDataObj => {
                                newCommentEntry.selected_samples.push(tableDataObj.sample_id);
                                newCommentEntry.sample_flag_selected.push(tableDataObj.flag);
                                newCommentEntry.comments.push(tableDataObj.comment);
                                newCommentEntry.CQ.push(tableDataObj?.CQ);
                                newCommentEntry.WellPosition.push(tableDataObj.WellPosition);
                            });

                            this.commentText.push(newCommentEntry);
                        }
                        resolve(null); // Resolve the promise after processing this item
                    }, 1000);
                });
            }
        }
    }

    initializeComments(dataObj: any) {
        // Populate comments from `dataObj.comment` array if available
        if (Array.isArray(dataObj.comment) && dataObj.comment.length > 0) {
            dataObj.comment.forEach((backendComment) => {
                const sampleIndex = dataObj.table_data.data.findIndex(
                    (sample) => sample.sample === backendComment.sample_id
                );
                if (sampleIndex !== -1) {
                    dataObj.table_data.data[sampleIndex].comment = backendComment.comment;
                }
            });
        }

    }

    objectKeys(obj: any): string[] {
        return Object.keys(obj);
    }

    exportPDF() {
        let maxDate = new Date();
        const maxUTCDate = this.datepipe.transform((maxDate), 'yyyy-MM-dd HH:mm:ss', 'UTC');
        const doc = new jsPDF();
        const pdfTable = this.htmlData?.nativeElement;
        let html = htmlToPdfmake(pdfTable.innerHTML);

        const documentDefinition = {
            content: [

                {
                    image: this.logo,
                    width: 150,
                    style: 'logo',

                }, {
                    text: maxUTCDate,
                    width: 35,
                    style: 'date',
                    bold: true,
                    fontSize: 10,

                },
                {
                    text: [{ text: 'Project Code: ', bold: true }, this.searchResult.projectCode],
                    width: 20,
                    alignment: 'left',
                    margin: [0, 20, 10, 0],
                    fontSize: 10,

                },
                {
                    text: [{ text: 'Target Name: ', bold: true }, this.searchResult.targetName],
                    width: 20,
                    alignment: 'left',
                    margin: [0, 5, 5, 0],
                    fontSize: 10,
                },
                {
                    text: [{ text: 'RedThread User Name: ', bold: true }, this.searchResult.userName],
                    width: 20,
                    alignment: 'left',
                    margin: [0, 5, 5, 0],
                    fontSize: 10,
                },
                {
                    text: [{ text: 'RedThread Analysis Date(UTC): ', bold: true }, this.searchResult.analysis_date],
                    width: 20,
                    alignment: 'left',
                    margin: [0, 5, 5, 0],
                    fontSize: 10,
                },
                {
                    text: ('RedThread User Uploaded File(s) Report'),
                    width: 100,
                    bold: true,
                    alignment: 'center',
                    margin: [10, 20, 10, 10],
                    decoration: 'underline',
                    fontSize: 15,
                },
                html
            ],
            styles: {
                logo: {
                    alignment: 'left',
                },
                date: {
                    alignment: 'right'
                }
            }

        };
        pdfMake.createPdf(documentDefinition).download(`RedThread User Uploaded File(s).pdf`);
    }

    exportAuditPDF() {
        let maxDate = new Date();
        const maxAuditUTCDate = this.datepipe.transform((maxDate), 'yyyy-MM-dd HH:mm:ss', 'UTC');
        const doc = new jsPDF();
        const pdfTable = this.auditData?.nativeElement;
        let html = htmlToPdfmake(pdfTable.innerHTML);
        const documentDefinition = {
            content: [
                {
                    image: this.logo,
                    width: 150,
                    style: 'logo',
                    margin: [0, 10, 10, 10],
                    fontSize: 10,
                },
                {
                    text: maxAuditUTCDate,
                    width: 35,
                    style: 'date',
                    fontSize: 10,
                },
                {
                    text: [{ text: 'Project Code: ', bold: true }, this.searchResult.projectCode],
                    width: 20,
                    alignment: 'left',
                    margin: [0, 20, 10, 0],
                    fontSize: 10,

                },
                {
                    text: [{ text: 'Target Name: ', bold: true }, this.searchResult.targetName],
                    width: 20,
                    alignment: 'left',
                    margin: [0, 5, 5, 0],
                    fontSize: 10,
                },
                {
                    text: [{ text: 'RedThread User Name: ', bold: true }, this.searchResult.userName],
                    width: 20,
                    alignment: 'left',
                    margin: [0, 5, 5, 0],
                    fontSize: 10,
                },
                {
                    text: [{ text: 'RedThread Analysis Date(UTC): ', bold: true }, this.searchResult.analysis_date],
                    width: 20,
                    alignment: 'left',
                    margin: [0, 5, 5, 0],
                    fontSize: 10,
                },
                {
                    text: ("AUDIT REPORT"),
                    width: 100,
                    bold: true,
                    alignment: 'center',
                    margin: [10, 20, 10, 10],
                    decoration: 'underline',
                    fontSize: 15,
                },
                html
            ],
            styles: {
                logo: {
                    alignment: 'left',
                },
                date: {
                    alignment: 'right',
                }
            }

        };
        pdfMake.createPdf(documentDefinition).download(`RedThread Audit.pdf`);

    }

    getSingedURL(path: string) {
        if (path === undefined) {
            return ' ';
        }
        if (path.indexOf('https') !== -1) {
            return path;
        } else if (/\S/.test(path)) {
            return this.downloadService.getUrl(path);
        } else {
            return ' ';
        }

    }

    getOutputFilePath() {
        const eventName = 'Final Result File Downloaded';
        this.callInitialEventData(eventName);
        return this.searchResult.output_file_path;

    }


    async pollForResult() {
        this.AuthService.getCurrentUser();
        this.currentLoginUser = this.AuthService.getCurrentLoginUser.subscribe(async (user: any) => {
            this.signedInUser = user;
            this.userGroup = this.signedInUser.attributes["custom:group"];
            this.userOrganization = this.signedInUser.attributes["custom:organization"]
            if (!this.signedInUser) {
                this.router.navigate(['/extra-layout/signin']);
                return;
            } else {
                this.signedInUserAccess = this.storageService.get('access')

                this.activatedroute.paramMap.subscribe(async (params) => {
                    if (this.signedInUserAccess == 'SU' || this.signedInUserAccess == 'GA' || this.signedInUserAccess == 'QA' || this.signedInUserAccess == 'PI') {
                        let iteration1 = localStorage.getItem("iteration1Called");
                        this.usr = params.get('user');
                        this.analysisId = params.get('id');
                       if(this.AnalysisViewType==null || iteration1 == 'true'){
                        const status = await this.fetchAnalysisStatus();
                       } 
                        this.dbService.getAnalysisDetails(this.usr, this.analysisId);
                        this.illegalUser = false;
                    }

                });
            }

        })
    }

    async fetchAnalysisStatus(): Promise<string> {
        let iteration1 = localStorage.getItem("iteration1Called");
        return new Promise((resolve, reject) => {
            this.pollingSubscription = interval(5000)
                .pipe(
                    switchMap(() =>
                        this.dbService.getAnalysisStatus(
                            this.usr,
                            this.analysisId,
                            this.userOrganization,
                            this.userGroup
                        )
                    ),
                    takeUntil(this.stopPolling),
                    catchError((error) => {
                        console.error('Polling error:', error);
                        reject(error);
                        return [];
                    })
                )
                .subscribe(
                    (data) => {
                        const dataStatus = data.data['analysis_status'];
                        if (iteration1 == 'true') {
                            if (dataStatus === 'Complete' || dataStatus === 'Error') {
                                this.stopPolling.next(); // Stop polling when the desired status is reached
                                resolve(dataStatus);
                                localStorage.setItem('iteration1Called','false')
                            }
                        }
                        else {
                            if (dataStatus === 'Complete' || dataStatus === 'Pending' || dataStatus === 'Error') {

                                this.stopPolling.next(); // Stop polling when the desired status is reached
                                resolve(dataStatus);
                            }
                        }
                    },
                    (error) => {
                        console.error('Error during polling:', error);
                        reject(error);
                    }
                );
        });
    }

    toggleSelection(dataObj: any, sample: any, idx) {
        // Toggle the sample's flag (checkbox state)
        idx = 10 * this.pageIndex + idx;
        sample.flag = !sample.flag;
        const runId = dataObj.run_id;
        const sampleName = sample.sample;
        const comment = sample.comment; // Default to an empty string if no comment is provided
        // Find the corresponding entry for the run_id in commentText
        let runEntry = this.commentText[idx];
        // If the sample is flagged (checkbox checked), add it to the selected samples
        if (sample.flag) {
            if (!runEntry.selected_samples.includes(sampleName)) {
                // Add sample name and comment if not already in the array
                runEntry.selected_samples.push(sampleName);
                runEntry.sample_flag_selected.push(sample.flag); // Track flag status
                runEntry.comments.push(comment);// Store the comment for the sample
                runEntry.CQ.push(null);
                runEntry.WellPosition.push(null);
            }
            else {
                const sampleIndex = runEntry.selected_samples.indexOf(sampleName);
                runEntry.sample_flag_selected[sampleIndex] = !runEntry.sample_flag_selected[sampleIndex];
                runEntry.CQ[sampleIndex] = null;
                runEntry.WellPosition[sampleIndex] = null;
                sample.individual_sample_flag = sample.individual_sample_flag.map(() => false)
            }
        } else {
            // If the sample is unflagged (checkbox unchecked), remove it from the selected samples
            const sampleIndex = runEntry.selected_samples.indexOf(sampleName);

            if (sampleIndex !== -1) {
                // Remove sample name, comment, and flag from the respective arrays
                runEntry.selected_samples.splice(sampleIndex, 1);
                runEntry.sample_flag_selected.splice(sampleIndex, 1);
                runEntry.comments.splice(sampleIndex, 1);
                runEntry.CQ.splice(sampleIndex, 1);
                runEntry.WellPosition.splice(sampleIndex, 1);
                // If there are no more selected samples for this run_id, remove the entry from commentText
                if (runEntry.selected_samples.length === 0) {
                    this.commentText = this.commentText.filter(entry => entry.run_id !== runId);
                }
            }
        }
        // After toggling, you may want to update the plot (if relevant)
        this.CreateUpdatePlot(dataObj, idx);
    }


    hasSelectedCheckboxes(dataObj: any): boolean {
        // Check if any sample has flag === true
        const hasFlagTrue = dataObj?.table_data?.data?.some((sample: any) => sample.flag === true);

        // Check if any individual_sample_flag array has true value
        const hasIndividualFlagTrue = dataObj?.table_data?.data?.some((sample: any) =>
            sample.individual_sample_flag?.some((individualFlag: boolean) => individualFlag === true)
        );
        return hasFlagTrue || hasIndividualFlagTrue;
    }

    shouldDisplayCommentBox(sample: any): boolean {
        return sample.flag || (sample.individual_sample_flag && sample.individual_sample_flag.some((flag: boolean) => flag === true));
    }


    async CreateUpdatePlot(dataObj: any, index): Promise<void> {
        this.updateSubmitEnabled();

        try {
            const data = dataObj.table_data.data;
            const selectedData = data.filter((item) => item.flag); // Selected samples
            const deselectedData = data.filter((item) => !item.flag); // Deselected samples
            // Extract the relevant values for plotting
            const selectedXValues = selectedData.map(item => item.quantity_log10);
            const selectedYValues = selectedData.map(item => item.cq_mean);
            const selectedSampleNames = selectedData.map(item => item.sample);

            const deselectedXValues = deselectedData.map(item => item.quantity_log10).map(value => {
                const num = parseFloat(value); // Convert each string to a number
                return isNaN(num) ? NaN : num; // Ensure 'NaN' strings are converted to actual NaN
            });;
            const deselectedYValues = deselectedData.map(item => item.cq_mean).map(value => {
                const num = parseFloat(value); // Convert each string to a number
                return isNaN(num) ? NaN : num; // Ensure 'NaN' strings are converted to actual NaN
            });
            const deselectedSampleNames = deselectedData.map(item => item.sample);

            const regression = this.calculateLinearRegression(deselectedXValues, deselectedYValues, index);
            const allXValues = data.map(item => item.quantity_log10);
            const allYValues = data.map(item => item.cq_mean);

            const xAxisRange = [Math.min(...allXValues), Math.max(...allXValues)];
            const yAxisRange = [Math.min(...allYValues), Math.max(...allYValues)];

            // Add margin of 5 to axis range
            const xMargin = (xAxisRange[1] - xAxisRange[0]) * 0.05;
            const yMargin = (yAxisRange[1] - yAxisRange[0]) * 0.05;

            const Data = [
                {
                    x: deselectedXValues,
                    y: deselectedYValues,
                    mode: 'markers',
                    type: 'scatter',
                    name: 'Data',
                    text: deselectedSampleNames,
                    textposition: 'top center',
                    marker: {
                        color: '#000000',
                        size: 10,
                        symbol: 'circle',
                    }
                },
                {
                    x: selectedXValues,
                    y: selectedYValues,
                    mode: 'markers',
                    type: 'scatter',
                    name: 'Deselected Values',
                    text: selectedSampleNames,
                    textposition: 'top center',
                    marker: {
                        color: '#0000FF',
                        size: 10,
                        symbol: 'diamond',
                    }
                },
                {
                    x: regression.x,
                    y: regression.y,
                    mode: 'lines',
                    type: 'scatter',
                    name: 'Linear Regression',
                    marker: {
                        color: '#20B2AA'
                    }

                },

            ];

            const layout = {
                title: 'Scatter Plot with Linear Regression',
                xaxis: {
                    title: 'Quantity (copies/rxn)',
                    range: [xAxisRange[0] - xMargin, xAxisRange[1] + xMargin]
                },
                yaxis: {
                    title: 'Mean Ct',
                    range: [yAxisRange[0] - yMargin, yAxisRange[1] + yMargin]
                }
            };
            // const plotId = 'plotlyDiv_' + dataObj.run_id;
            const plotId = `plotlyDiv_${dataObj.run_id}`;
            const element = document.getElementById(plotId);
            if (element) {
                await Plotly.newPlot(plotId, Data, layout);
            } else {
                console.error(`Element with ID ${plotId} not found.`);
            }
        }
        // Ensure DOM updates
        catch (error) {
            console.error('Error creating plot:', error);
        }
        finally {
            this.graphLoadingStates[index] = false;
            this.cdr.detectChanges();
        }

    }


    calculateLinearRegression(xValues: number[], yValues: number[], index) {
        const filteredData = xValues
            .map((x, index) => ({ x, y: yValues[index] }))
            .filter(item => item.y !== null && !isNaN(item.y));
        const x = filteredData.map(item => item.x);
        const y = filteredData.map(item => item.y as number);
        const n = x.length;
        const sumX = x.reduce((acc, val) => acc + val, 0);
        const sumY = y.reduce((acc, val) => acc + val, 0);
        const sumXY = x.reduce((acc, val, i) => acc + val * y[i], 0);
        const sumXSquare = x.reduce((acc, val) => acc + val * val, 0);

        const slope = (n * sumXY - sumX * sumY) / (n * sumXSquare - sumX * sumX);
        const intercept = (sumY - slope * sumX) / n;
        const regressionLineX = [Math.min(...xValues), Math.max(...xValues)];
        const regressionLineY = [slope * regressionLineX[0] + intercept, slope * regressionLineX[1] + intercept];
        return { x: regressionLineX, y: regressionLineY };
    }

    trackByIndex(index: number, item: any): number {
        return index; // Ensure each row is uniquely identified by its index
    }

    isCheckboxDisabled(flags: boolean[], currentIndex: number): boolean {
        // Check if any checkbox in the row is selected, and disable others
        return flags.some((flag, index) => flag && index !== currentIndex);
    }

    toggleCqSelection(dataObj: any, sample: any, index: number, idx) {
        idx = 10 * this.pageIndex + idx
        sample.individual_sample_flag[index] = !sample.individual_sample_flag[index];
        const run_id = dataObj.run_id;
        const sample_name = sample.sample;
        const comment = sample.comment;
        let commentEntry = this.commentText[idx];

        const sampleIndex = commentEntry?.selected_samples?.indexOf(sample_name);
        if (sampleIndex === -1) {
            // Add new sample name and corresponding comment
            commentEntry.selected_samples.push(sample_name);
            commentEntry.sample_flag_selected.push(sample.flag);
            commentEntry.comments.push(comment);
        } else {
            // Update the comment for the existing sample name
            commentEntry.comments[sampleIndex] = comment;
            commentEntry.sample_flag_selected[sampleIndex] = sample.flag;
        }

        // Get the corresponding cq value based on the index
        const cqValue = sample.cq[index];
        const WellValue = sample.well_position[index]

        // Update the cq array for the corresponding run_id
        if (sample.individual_sample_flag[index]) {
            // If checkbox is checked, add the cq value to the cq array
            if (!commentEntry.CQ?.includes(cqValue)) {
                commentEntry.CQ.push(cqValue);
            }
            if (!commentEntry.WellPosition?.includes(WellValue)) {
                commentEntry.WellPosition.push(WellValue);
            }

        } else {
            // If checkbox is unchecked, remove the cq value from the cq array
            const indexToRemove = commentEntry?.CQ?.indexOf(cqValue);
            if (indexToRemove > -1) {
                commentEntry.CQ.splice(indexToRemove, 1);
                commentEntry.WellPosition.splice(indexToRemove, 1);

            }
            const sampleNameIndex = commentEntry.selected_samples.indexOf(sample_name);
            if (sampleNameIndex > -1) {
                commentEntry.selected_samples.splice(sampleNameIndex, 1);
                commentEntry.comments.splice(sampleNameIndex, 1);
                commentEntry.sample_flag_selected.splice(sampleNameIndex, 1);
            }
        }
        this.updateSubmitEnabled();
    }

    addComment(dataObj, index: number, idx: number) {
        idx = 10 * this.pageIndex + idx;
        const sample = dataObj.table_data?.data[index];
        const comment = sample?.comment?.trim() || ''; // Get the trimmed comment
        const sampleName = sample.sample; // Get the sample name
        const sampleFlag = sample.flag; // Get the flag value

        if (idx !== -1) {
            // If entry exists, find the index of the sample in the selected samples array
            const sampleIndex = this.commentText[idx].selected_samples.indexOf(sampleName);

            if (sampleIndex !== -1) {
                // Update the existing comment, flag, CQ, and RE for the sample
                this.commentText[idx].comments[sampleIndex] = comment;
                this.commentText[idx].sample_flag_selected[sampleIndex] = sampleFlag;
            } else {
                // Add new sample, comment, and flag if not found
                this.commentText[idx].selected_samples.push(sampleName);
                this.commentText[idx].comments.push(comment);
                this.commentText[idx].sample_flag_selected.push(sampleFlag);

                // Add corresponding CQ and RE (use appropriate values based on your data)
                const cqValue = sample.cq[index];
                this.commentText[idx].CQ.push(cqValue);

            }
        } else {
            // Create a new entry for this run_id if it doesn't exist
            const cqValue = sample.cq[index];
            const WellValue = sample.well_position[index]

            this.commentText.push({
                run_id: dataObj.run_id,
                selected_samples: [sampleName],
                comments: [comment],
                sample_flag_selected: [sampleFlag], // Store flag status
                CQ: [cqValue], // Add corresponding CQ
                WellPosition: [WellValue]
            });
        }
        this.updateSubmitEnabled(); // Update the submit button status based on changes
    }



    get isCommentsDisabled(): boolean {
        return this.searchResult.iteration === 1;
    }

    trackBySampleId(index: number, sample: any): string | null {
        return sample?.id || null; // Adjust based on unique identifier
    }

    isSubmitEnabled(): boolean {
        for (let commentObj of this.commentText) {
            let comments = commentObj.comments.filter((res) => res !== "" && res !== null);
            if (commentObj.selected_samples.length !== comments.length) {
                return false;
            }
        }
        return true;
    }


    updateSubmitEnabled() {
        this.submitEnabled = this.isSubmitEnabled();
    }


    isRecalculateEnabled(dataObj: any) {
        let flagCondition = true
        let individualFlagCondition = true;
        const refreshState = dataObj.table_data.data.some(sample => {

            // Check if flag is true and a comment is entered
            flagCondition = sample.flag && sample.comment.trim().length == 0;
            // Check if any individual_sample_flag is true and a comment is entered
            individualFlagCondition = sample.individual_sample_flag.some(flag => flag) && sample.comment.trim().length == 0;
            return flagCondition || individualFlagCondition;

        });
        return refreshState;
    }

    onCommentComplete(index, dataObj) {
        index=10*this.pageIndex + index;
        let regressiondata = {
            data: [this.commentText[index]],
            filePath: this.searchResult.file_path,
            processingType: 'regression'
        }
        this.recalculationStates[dataObj?.run_id] = true;
        this.panelStates[dataObj?.run_id] = true;
        this.http.post(this.regressionUrl, regressiondata).subscribe((res: any) => {
            try {
                let updatedGraphData = [...this.graphdata];
                updatedGraphData[index] = { ...res.data };
                // Re-assign MatTableDataSource to trigger change detection
                this.graphdata = updatedGraphData;
                this.paginatedData.data = updatedGraphData;
                this.recalculationStates[dataObj?.run_id] = false;
                this.renderGraphsForCurrentPage();
                this.cdr.detectChanges();
            }
            catch (error) {
                console.log(error);
                this.regressionApiError = "Could not refresh graph data, please contact administrator."
            }

        })
    }

    submitGraphDetails() {
        this.submitResultLoader = true;
        this.submitEnabled = false;
        this.executionId = Math.floor(new Date().getTime() / 1000.0).toString();
        this.searchResult.executionId = this.executionId;
        this.searchResult.commentText = this.commentText;
        localStorage.setItem('iteration1Called', 'true');
        this.http.post(this.AnalysisDataURL, this.searchResult).subscribe(
            res => {
                window.location.reload();
            },
            error => {
                console.log(error)
            }
        )

    }

    isValidNumber(value: any): boolean {
        return value !== null && value !== undefined && !isNaN(value) && value !== 'Undetermined';
    }

    setStep(index: number) {
        this.step = index;

    }
    ngOnDestroy() {
        // Unsubscribe from the observable to avoid memory leaks
        if (this.singleSearchData) {
            this.singleSearchData.unsubscribe();
        }
        if (this.currentLoginUser) {
            this.currentLoginUser.unsubscribe();
        }
        if (this.getEventData) {
            this.getEventData.unsubscribe();
        }
        if (this.PostEventData) {
            this.PostEventData.unsubscribe();
        }
    }
}

