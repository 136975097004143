import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {Injectable} from '@angular/core';
import {AuthService} from '../service';
import {Router} from '@angular/router';
import { AuthNewService } from '../service/auth-new.service';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router,
                private AuthService: AuthNewService) {}

    // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    //     this.AuthService.getAuthenticatedUser()?.getSession((err, session) => {
    //         if (err) {
    //             console.log('Error in getting Session forwarding to login page');
            
    //             return;
    //         }
    //         const credential = session.getIdToken().getJwtToken();
          
    //         req = req.clone({headers: req.headers.append('Authorization', credential)});
           
    //     });
    //     return next.handle(req);
    // }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.getToken()).pipe(
            mergeMap(token => {
                if (token) {
                    req = req.clone({ 
                        setHeaders: { Authorization: token }
                    });
                }
                return next.handle(req);
            })
        );
    }


    private async getToken(): Promise<string | null> {
        try {
            const user = this.AuthService.getAuthenticatedUser();
            if (!user) {
                console.log('User not authenticated');
                return null;
            }

            return new Promise<string>((resolve, reject) => {
                user.getSession((err, session) => {
                    if (err) {
                        console.log('Error in getting session, redirecting to login');
                        reject(null);
                    } else {
                        // Check token expiration and refresh if needed
                        const idToken = session.getIdToken();
                        const tokenExpiration = idToken.getExpiration();
                        const currentTime = Math.floor(Date.now() / 1000);

                        if (tokenExpiration - currentTime < 60) { // Refresh token if it's about to expire in 60 seconds
                            user.refreshSession(session.getRefreshToken(), (err, newSession) => {
                                if (err) {
                                    console.log('Error refreshing session', err);
                                    reject(null);
                                } else {
                                    resolve(newSession.getIdToken().getJwtToken());
                                }
                            });
                        } else {
                            resolve(idToken.getJwtToken());
                        }
                    }
                });
            });
        } catch (error) {
            console.log('Error retrieving token:', error);
            return null;
        }
    }
}
