import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isJSDocThisTag } from 'typescript';
import { AuthNewService } from '../../../service/auth-new.service';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-user-forgot-password',
  templateUrl: './user-forgot-password.component.html',
  styleUrls: ['./user-forgot-password.component.scss']
})
export class UserForgotPasswordComponent implements OnInit {

  forgetPasswordForm: FormGroup;
  forgetPasswordConfirmForm: FormGroup;

  codeRecieved = false;
  submitted = false;
  errorMessageForgot;
  errorMessageConfirm;
  username;
  hide = true;
  passwordPattern = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?!.*\\s)(?=.*?[#?!@$%^&*-]).{8,}$';
  validatePasswordURL: string = environment.serverUrl + '/validate-p-hash';
  passwordtype: string;


  constructor(private fb: FormBuilder, private authservice: AuthNewService, private router: Router, private activatedroute: ActivatedRoute, private http: HttpClient, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.activatedroute.queryParams.subscribe(params => {
      this.passwordtype = params['passtype'];
    });

    this.forgetPasswordForm = this.fb.group({
      username: ['', Validators.required]
    })

    this.forgetPasswordConfirmForm = this.fb.group({
      code: [null, Validators.required],
      password: [null, [Validators.required, Validators.pattern(this.passwordPattern)]]
    })

  }
  get formControls() { return this.forgetPasswordForm.controls; }
  get formControls1() { return this.forgetPasswordConfirmForm.controls; }



  UserForgetPassword() {
    this.submitted = true;
    this.errorMessageForgot = '';
    const name = this.forgetPasswordForm.value.username;
    this.username = name;
    this.authservice.forgetPassword(name);
    setTimeout(() => {
      this.authservice.forgotPasswordErrorMessage.subscribe((error: any) => {
        if (error instanceof Error && error.message.includes('no registered/verified email or phone_number')) {
          this.errorMessageForgot = "email/phone number is not verified. Please contact administrator."
          this.submitted = false;
        }
        else if (error instanceof Error && error.message.includes('Attempt limit exceeded, please try after some time')) {
          this.errorMessageForgot = "Attempt limit exceeded, please try after some time."
          this.submitted = false;
        }
        else if (error instanceof Error && error.message.includes('User is disabled.')) {
          this.errorMessageForgot = 'Your account has been blocked. Please contact support.'
          this.submitted = false;
        }
        else {
          this.authservice.forgotPasswordCode.subscribe((res) => {
            try {
              if (res === true) {
                this.codeRecieved = true; 
                this.submitted = false;
                this.errorMessageForgot=''
              }
              else {
                this.errorMessageForgot = 'No user found.'
                this.submitted = false;
              }
            }
            catch (error) {
              console.log(error)
            }
          }
          )
        }
      })
    }, 2000)
  }

  UserForgetPasswordConfirm() {
    this.errorMessageForgot = '';
    this.submitted = true;
    const username = this.forgetPasswordForm.value.username;
    const code = this.forgetPasswordConfirmForm.value.code;
    const password = this.forgetPasswordConfirmForm.value.password;
    const hashedText = {
      username: username,
      hashpassword: CryptoJS.SHA256(password).toString(),
    }
    this.authservice.forgetPasswordConfirm(this.username, code, password, hashedText);
    setTimeout(() => {
      this.authservice.forgotPasswordMessage.subscribe((message) => {
        if (message === 'password changed') {
          this.router.navigate(['extra-layout/signin'])
        } else if (message === 'Error occured') {
          this.errorMessageForgot = message;
          this.submitted = false;
        }
        else if (message.includes("cannot reuse any of your last 3 passwords")) {
          this.errorMessageForgot = "New password must be different from the last three passwords.";
          this.submitted = false;
        }
      })
    }, 2000)
   
  }


}
