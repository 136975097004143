import { ChangeDetectorRef, Component, OnInit, OnDestroy, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { DownloadFile, SearchResult } from '../../../model/analysis-details.model';
import { DBService, DownLoadService } from '../../../service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../../../service/auth.service';
import { interval } from 'rxjs/internal/observable/interval';
import { User } from '../../../model/types';
import { SharedService } from '../../../layouts/shared-service';
import { Subscription } from 'rxjs/Subscription';
import { AuthNewService } from '../../../service/auth-new.service';
import { Subject } from 'rxjs';
import { delay, shareReplay, take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { StorageService } from '../../../service/storage.service';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import jsPDF from 'jspdf';
const htmlToPdfmake = require("html-to-pdfmake");
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
import { DatePipe } from '@angular/common';
import autoTable from 'jspdf-autotable'

@Component({
    selector: 'app-qpcr-result-page',
    templateUrl: './qpcr-result-page.component.html',
    styleUrls: ['./qpcr-result-page.component.scss']
})
export class QpcrResultPageComponent implements OnInit {
    maxDate;
    pageTitle = 'Analyze Report/Data';
    searchResult: SearchResult = <SearchResult>{};
    searchResultData: boolean = true;
    isFetching: boolean = true;
    signedInUser: any;
    currentUser: User;
    illegalUser: boolean;
    usr: any;
    analysisId = '';
    navigationSubscription: Subscription;
    qpcrTables = []
    step = 0;
    urlPath: string;
    signedInUserAccess;
    errorResult: string;
    private singleSearchData: Subscription;
    private currentLoginUser: Subscription;
    private PostEventData: Subscription;
    private getEventData: Subscription;
    EventDataUrl: string = environment.serverUrl + '/event-data';
    DownloadFileobject: DownloadFile = <DownloadFile>{};
    AnalysisViewType: string;
    QpcrPdfData
    AuditPdfData
    @ViewChild('htmlData', { static: false }) htmlData!: ElementRef;
    @ViewChild('auditData', { static: false }) auditData!: ElementRef;
    logo: string;
    


    constructor(
        private http: HttpClient,
        private dbService: DBService,
        private activatedroute: ActivatedRoute,
        private AuthService: AuthNewService,
        private router: Router,
        private _sharedService: SharedService,
        private downloadService: DownLoadService,
        private storageService: StorageService,
        public datepipe: DatePipe,

    ) {

        this._sharedService.emitChange(this.pageTitle);
       


    }

   async ngOnInit() {
        this.pollForResult();
        this.AnalysisViewType = this.activatedroute.snapshot.paramMap.get('type');
        // this.singleSearchData =await this.dbService.singleSearch.subscribe(
        //     (data: SearchResult) => {
        //         if (Object.keys(data).length === 0) {
        //             this.searchResultData = false;

        //         } else {
        //             this.isFetching = false;
        //          
        //         }
        //         data && this.populateResultData(data);
        //     }
        // );
        try {
            const data = await this.dbService.singleSearch.pipe(take(1)).toPromise();
            if (Object.keys(data).length === 0) {
                this.searchResultData = false;
            } else {
                this.isFetching = false;
             
            }
            data && this.populateResultData(data);
            this.loadImageAsBase64('/assets/img/App-Biodata.png');
        } catch (error) {
            console.error('Error occurred:', error);
        }


    }

    loadImageAsBase64(url: string) {
        this.http.get(url, { responseType: 'blob' }).subscribe((blob: Blob) => {
            const reader = new FileReader();
            reader.onload = () => {
                this.logo = reader.result as string;
            };
            reader.readAsDataURL(blob);
        });
    }


     populateResultData(data: SearchResult) {
        this.searchResult = data;
        this.errorResult = this.searchResult.error_message;
        this.QpcrPdfData = this.searchResult.pdf_data;
        let output_file = this.getSingedURL(this.searchResult.output_result_path);
        this.searchResult.output_file_path = output_file;
        
        const eventName = 'View';
        this.DownloadFileobject.userId = this.storageService.get('username');
        this.DownloadFileobject.eventName = eventName;
        this.DownloadFileobject.analysisId = this.analysisId;
        this.DownloadFileobject.userName = this.storageService.get('name');
        this.DownloadFileobject.filePath = this.searchResult?.audit_file_path;
        this.DownloadFileobject.userRole = this.storageService.get('access');

        let params = new HttpParams();
        params = params.append('analysisId', this.analysisId)

            this.PostEventData = this.http.post(this.EventDataUrl, this.DownloadFileobject).subscribe(
                (response) => {

                    this.getEventData = this.http.get(this.EventDataUrl, { params: params }).subscribe(
                        (res) => {
                            this.AuditPdfData = res;
            
                        }
                    )
                },
                (error) => {
                    console.error('API call error:', error);
                }
            );
        
       
       


    }

    exportPDF() {
        let maxDate = new Date();
       const maxUTCDate = this.datepipe.transform((maxDate), 'yyyy-MM-dd HH:mm:ss', 'UTC');
        const doc = new jsPDF();
        const pdfTable = this.htmlData?.nativeElement;
        let html = htmlToPdfmake(pdfTable.innerHTML);

        const documentDefinition = {
            content: [

                {
                    image: this.logo,
                    width: 150,
                    style: 'logo',

                }, {
                    text: maxUTCDate,
                    width: 35,
                    style: 'date',
                    bold: true,
                    fontSize: 10,

                },
                {
                    text: [{ text: 'Project Code: ', bold: true }, this.searchResult.projectCode],
                    width: 20,
                    alignment: 'left',
                    margin: [0, 20, 10, 0],
                    fontSize: 10,

                },
                {
                    text:  [{ text: 'Target Name: ', bold: true }, this.searchResult.targetName],
                    width: 20,
                    alignment: 'left',
                    margin: [0, 5, 5, 0],
                    fontSize: 10,
                },
                {
                    text: [{ text: 'RedThread User Name: ', bold: true }, this.searchResult.userName],
                    width: 20,
                    alignment: 'left',
                    margin: [0, 5, 5, 0],
                    fontSize: 10,
                },
                {
                    text:  [{ text: 'RedThread Analysis Date(UTC): ' , bold: true },this.searchResult.analysis_date],
                    width: 20,
                    alignment: 'left',
                    margin: [0, 5, 5, 0],
                    fontSize: 10,
                },
                {
                    text: ('RedThread User Uploaded File(s) Report'),
                    width: 100,
                    bold: true,
                    alignment: 'center',
                    margin: [10, 20, 10, 10],
                    decoration: 'underline',
                    fontSize: 15,
                },
                html
            ],
            styles: {
                logo: {
                    alignment: 'left',
                },
                date: {
                    alignment: 'right'
                }
            }

        };
        pdfMake.createPdf(documentDefinition).download(`RedThread User Uploaded File(s).pdf`);
    }

    exportAuditPDF() {
        let maxDate = new Date();
       const maxAuditUTCDate = this.datepipe.transform((maxDate), 'yyyy-MM-dd HH:mm:ss', 'UTC');
        const doc = new jsPDF();
        const pdfTable = this.auditData?.nativeElement;
        let html = htmlToPdfmake(pdfTable.innerHTML);
        const documentDefinition = {
            content: [
                {
                    image: this.logo,
                    width: 150,
                    style: 'logo',
                    margin: [0, 10, 10, 10],
                    fontSize: 10,
                },
                {
                    text: maxAuditUTCDate,
                    width: 35,
                    style: 'date',
                    fontSize: 10,
                },
                {
                    text: [{ text: 'Project Code: ', bold: true }, this.searchResult.projectCode],
                    width: 20,
                    alignment: 'left',
                    margin: [0, 20, 10, 0],
                    fontSize: 10,

                },
                {
                    text:  [{ text: 'Target Name: ', bold: true }, this.searchResult.targetName],
                    width: 20,
                    alignment: 'left',
                    margin: [0, 5, 5, 0],
                    fontSize: 10,
                },
                {
                    text: [{ text: 'RedThread User Name: ', bold: true }, this.searchResult.userName],
                    width: 20,
                    alignment: 'left',
                    margin: [0, 5, 5, 0],
                    fontSize: 10,
                },
                {
                    text:  [{ text: 'RedThread Analysis Date(UTC): ' , bold: true },this.searchResult.analysis_date],
                    width: 20,
                    alignment: 'left',
                    margin: [0, 5, 5, 0],
                    fontSize: 10,
                },
                {
                    text: ("AUDIT REPORT"),
                    width: 100,
                    bold: true,
                    alignment: 'center',
                    margin: [10, 20, 10, 10],
                    decoration: 'underline',
                    fontSize: 15,
                },
                html
            ],
            styles: {
                logo: {
                    alignment: 'left',
                },
                date: {
                    alignment: 'right',
                }
            }

        };
        pdfMake.createPdf(documentDefinition).download(`RedThread Audit.pdf`);

    }


    getSingedURL(path: string) {
        if (path === undefined) {
            return ' ';
        }
        if (path.indexOf('https') !== -1) {
            return path;
        } else if (/\S/.test(path)) {
            return this.downloadService.getUrl(path);
        } else {
            return ' ';
        }

    }

    async getOutputFilePath() {
        const eventName = 'Download'
       this.DownloadFileobject.userId = this.storageService.get('username');
        this.DownloadFileobject.eventName = eventName;
        this.DownloadFileobject.analysisId = this.analysisId;
        this.DownloadFileobject.userName = this.storageService.get('name');
        this.DownloadFileobject.filePath = this.searchResult.file_path;
        let params = new HttpParams();
        params = params.append('analysisId', this.analysisId)
       
            await this.http.post(this.EventDataUrl, this.DownloadFileobject).subscribe(
                (response) => {
                   

                    this.getEventData = this.http.get(this.EventDataUrl, { params: params }).subscribe(
                        (res) => {
                            this.AuditPdfData = res;

                        }
                    )
                },
                (error) => {
                    console.error('API call error:', error);
                }
            );
           return this.searchResult.output_file_path;
        
    }


    pollForResult() {
        this.AuthService.getCurrentUser();
        this.currentLoginUser = this.AuthService.getCurrentLoginUser.subscribe((user: any) => {
            this.signedInUser = user;
            if (!this.signedInUser) {
                this.router.navigate(['/extra-layout/signin']);
                return;
            } else {
              
                this.signedInUserAccess =  this.storageService.get('access')
                this.activatedroute.paramMap.subscribe((params) => {
                    if (this.signedInUserAccess == 'SU' || this.signedInUserAccess == 'GA' || this.signedInUserAccess == 'QA' || this.signedInUserAccess == 'PI') {
                     
                        this.usr = params.get('user');
                        this.analysisId = params.get('id');
                        this.dbService.getAnalysisDetails(this.usr, this.analysisId);
                        this.illegalUser = false;
                    }

                });
            }

        })
    }


    setStep(index: number) {
        this.step = index;

    }
    ngOnDestroy() {
        // Unsubscribe from the observable to avoid memory leaks
        if (this.singleSearchData) {
            this.singleSearchData.unsubscribe();
        }
        if (this.currentLoginUser) {
            this.currentLoginUser.unsubscribe();
        }
        if (this.getEventData) {
            this.getEventData.unsubscribe();
        }
        if (this.PostEventData) {
            this.PostEventData.unsubscribe();
        }
    }
}

