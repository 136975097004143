import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { AuthNewService } from '../../../service/auth-new.service';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { error } from 'console';
import { Auth } from 'aws-amplify';
import { StorageService } from '../../../service/storage.service';

@Component({
  selector: 'app-user-new-password',
  templateUrl: './user-new-password.component.html',
  styleUrls: ['./user-new-password.component.scss']
})
export class UserNewPasswordComponent implements OnInit {

  hide = true;
  passwordPattern = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?!.*\\s)(?=.*?[#?!@$%^&*-]).{8,}$';
  show: boolean;
  ChangePasswordConfirmForm: FormGroup;
  submitted = false;
  firstTimePasswordForm: FormGroup;
  submissionError: string;
  firstTimePassword = false;
  freshUserPassword: string;
  oldUserPassowrd: string;
  oldUserConfirmPassword: string;
  freshUserConfirmPassword: string;
  infoMessage: string;
  isValid: boolean;
  user: CognitoUser;
  changePasswordError: string;
  passwordtype: string;
  changePasswordURL: string = environment.serverUrl + '/check-password';

  constructor(public formBuilder: FormBuilder, public router: Router, private authservice: AuthNewService, private activatedroute: ActivatedRoute, private http: HttpClient, private storageservice: StorageService) {
    this.show = false;
  }

  passwordHide() {
    this.show = !this.show;
  }
  get newUserFormControls() { return this.firstTimePasswordForm.controls; }
  get formControls2() { return this.ChangePasswordConfirmForm.controls; }

  ngOnInit(): void {
    this.activatedroute.queryParams.subscribe(params => {
      this.passwordtype = params['passtype'];
    });

    this.firstTimePasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.pattern(this.passwordPattern)]],
      confirmPassword: ['', Validators.required],
      disclaimer: [false, Validators.requiredTrue]
    });
    this.ChangePasswordConfirmForm = this.formBuilder.group({
      // code: ['', Validators.required],
      oldpassword: ['', Validators.required],
      newpassword: ['', [Validators.required, Validators.pattern(this.passwordPattern)]],
      confirmnewpassword: ['', [Validators.required, Validators.pattern(this.passwordPattern)]]
    })
  }

  private validateNewPassword() {
    this.isValid = true;
    this.freshUserPassword = this.firstTimePasswordForm.value.password;
    this.freshUserConfirmPassword = this.firstTimePasswordForm.value.confirmPassword;
    if (this.freshUserConfirmPassword !== this.freshUserPassword) {
      this.isValid = false;
      this.submissionError = 'Confirm password should match new password.';
    }
    return this.isValid;
  }

  updatePassword() {
    if (!this.validateNewPassword()) {
      return
    }
    this.submitted = true;
    this.freshUserPassword = this.firstTimePasswordForm.value.password;
    this.freshUserConfirmPassword = this.firstTimePasswordForm.value.confirmPassword;
    this.authservice.newPassword(this.freshUserPassword)
    this.authservice.passwordChanged.subscribe((value) => {
      if (value === false) {
        return
      } else {
        this.infoMessage = 'Password has been updated successfully.'
        this.router.navigate(['/extra-layout/signin'], { queryParams: { passtype: 'hash' } })
      }
    })
  }

  private validateChangePassword() {
    this.isValid = true;
    this.oldUserPassowrd = this.ChangePasswordConfirmForm.value.newpassword;
    this.oldUserConfirmPassword = this.ChangePasswordConfirmForm.value.confirmnewpassword;
    if (this.oldUserConfirmPassword !== this.oldUserPassowrd) {
      this.isValid = false;
      // this.submissionError = 'Confirm password should match new password.';
    }
    return this.isValid;
  }

  UserChangePasswordConfirm() {
      this.changePasswordError = ''
    if (!this.validateChangePassword()) {   
      this.changePasswordError='Confirm password should match new password.'
    }
    else{
      this.submitted = true;
      const user = this.storageservice.get('username')
  
      const oldpassword = this.ChangePasswordConfirmForm.value.oldpassword;
      const password = this.ChangePasswordConfirmForm.value.confirmnewpassword;
      const customAttributes = {
        userName: user,
        oldHashPassword: CryptoJS.SHA256(oldpassword).toString(),
        hashPassword: CryptoJS.SHA256(password).toString(),
      };
      this.http.post(this.changePasswordURL, customAttributes).subscribe(
        res => {
          try {
            const message: any = res;
            if (!message.password_valid) {
              this.changePasswordError = "Incorrect old password";
              this.submitted = false;
            }
            else if (message.reused) {
              this.changePasswordError = "New password must be different from the last three passwords.";
              this.submitted = false;
            }
  
            else {
              this.authservice.changePassword(oldpassword, password);
              setTimeout(() => {
                this.authservice.changeMessage?.subscribe((message) => {
                  if (message === 'SUCCESS') {
                    this.router.navigate(['default-layout/previous-analysis'])
                  }
                  else {
                    this.changePasswordError = "Something went wrong. Please try after sometime.";
                    this.submitted=false;
                  }
                })
              }, 2000)
            }
          }
          catch (error) {
            console.log(error)
          }
        },
        error => {
          console.log(error);
          this.changePasswordError = "Something went wrong. Please try after sometime.";
          this.submitted=false;
        }
      )
    }
    
  }


}
