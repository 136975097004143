import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import {AuthService} from '../../service/auth.service';
import {User} from '../../model/types';
import { URLUtil } from '../../../utils';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { DownLoadService } from '../../service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  signedInUser: User;
  baseUrl: string;
  data: any;

  @ViewChild('player', {static: false}) player: any;
  videoId: string;

  @Input()
  set id(id: string) {
    this.videoId = id;
  }

  constructor(private authService: AuthService, private router: Router, public dialog: MatDialog, private downloadService: DownLoadService,
  ) { }

  ngOnInit() {
    this.baseUrl = URLUtil.getBaseUrl();
  }

  public downloadFile(key: string) {
    this.downloadService.getS3Obj(key).then(data => {
     
      // this.data = JSON.parse(data);
    
      // const result = new TextDecoder('utf-8').decode(data.Body);
  
    });
    // const s3_path = this.downloadService.getUrl(key);
    

  }

}
