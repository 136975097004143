export const environment = {
  production: false,
  serverUrl: 'https://qvfg5k5vhh.execute-api.us-east-2.amazonaws.com/dev',
  bucket: 'ariadne-test-upload',
  userPool: {
    UserPoolId: 'us-east-2_JuNONm18U',
    ClientId: '1k6jj34sukr37oap44bmq52ub1',
    region: 'us-east-2'
  },
  identityPoolId: 'us-east-2:29441e96-ab25-4923-982e-3842400faf11',
  buckets: {
    'us-east-2': 'ariadne-test-upload'
  }, 
  modules: {
    key: 'analysisType',
    values: [
        {
          'name': 'qPCR',
          'desc': 'qPCR'
        }
    ]
},
  defaultRegion: 'us-east-2',
  cognito: {
    identityPoolId: 'us-east-2:29441e96-ab25-4923-982e-3842400faf11', // REQUIRED - Amazon Cognito Identity Pool ID
    region: 'us-east-2', // REQUIRED - Amazon Cognito Region
    userPoolId: 'us-east-2_JuNONm18U', // OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: '1k6jj34sukr37oap44bmq52ub1', // OPTIONAL - Amazon Cognito Web Client ID
  },
  Storage: {
    AWSS3: {
      bucket: 'ariadne-test-upload',
      region: 'us-east-2', // OPTIONAL -  Amazon service region
    }
  }
};
const awsconfig = {
  'aws_project_region': 'us-east-2',
  'aws_cognito_region': 'us-east-2',
  'aws_user_pools_id': 'us-east-2_JuNONm18U',
  'aws_user_pools_web_client_id': '1k6jj34sukr37oap44bmq52ub1',
  'aws_cognito_mfa_configuration': 'OPTIONAL',

};
export default awsconfig;
